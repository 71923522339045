import { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { deleteJwt, getHeaderObject, getJwt } from '../../../../util/jwtProvider';
import { Formik, FormikValues } from 'formik';
import { buildingYearRangeOptions, gebaeudeinformationenSchema, GebaeudeinformationenType } from './types';
import * as React from 'react';
import Colors from '../../../../util/colors';
import { Link } from 'react-router-dom';
import GebaeudeinformationenForm from './GebaeudeinformationenForm/GebaeudeinformationenForm';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import ExposimeterForm from './GebaeudeinformationenForm/ExposimeterForm';
import Gebaeudeinformationen from '../Gebaeudeinformationen';

type Props = {
  onRefresh: () => void;
};

const GebaeudeinformationenView = ({ onRefresh }: Props): JSX.Element => {
  const [data, setData] = useState(null as GebaeudeinformationenType | null);
  const [error, setError] = useState(null as string | null);
  const [status, setStatus] = useState(null as boolean | null);
  const [submitting, setSubmitting] = useState(false);

  const [selectedAccordion, setSelectedAccordion] = useState(0);

  useEffect(() => {
    if (getJwt() === null) {
      onRefresh();
      return;
    }
    fetch('/api/building.php', {
      headers: getHeaderObject(),
    })
      .then(async response => {
        if (response.status === 200) {
          const data: GebaeudeinformationenType = await response.json();
          if (
            typeof data.building.buildingYear === 'string' &&
            buildingYearRangeOptions.includes(data.building.buildingYear)
          ) {
            data.building.buildingYearRange = data.building.buildingYear;
            data.building.buildingYear = undefined;
          }

          if (data.building.buildingYearRange) {
            data.building.buildingYearUnknown = true;
          } else {
            data.building.buildingYearUnknown = false;
            data.building.buildingYearRange = '';
          }
          if (!data.building.buildingYear) {
            data.building.buildingYear = undefined;
          }

          setData(data);
        } else if (response.status === 204) {
          setError('Leider sind noch keine Exposimeterdaten vorhanden!');
        } else if (response.status === 400) {
          setError('Bitte überprüfen Sie Ihre Abgaben');
        } else {
          setError('Fehler beim Laden');
          onRefresh();
        }
      })
      .catch(() => {
        setError('Fehler beim Laden');
        onRefresh();
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    deleteJwt();
    onRefresh();
  };

  const submit = (values: FormikValues) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const valuesClone = JSON.parse(JSON.stringify(values));
    if (valuesClone.building.buildingYearUnknown) {
      valuesClone.building.buildingYear = valuesClone.building.buildingYearRange;
    }

    fetch('/api/building.php', {
      method: 'POST',
      body: JSON.stringify(valuesClone),
      headers: getHeaderObject(),
    })
      .then(async response => {
        setStatus(response.status === 201);
      })
      .catch(() => {
        setStatus(false);
      })
      .finally(() => {
        window.scrollTo(0, 0);
        setSubmitting(false);
      });
  };

  const handleBack = () => {
    setError(null);
    logout();

    window.location.href = Gebaeudeinformationen.defaultRoute;
  };

  if (error) {
    return (
      <div>
        <p>
          <h4 style={{ color: Colors.red }}>{error}</h4>
        </p>
        <p>
          <Link to="#" onClick={handleBack}>
            Zurück
          </Link>
        </p>
      </div>
    );
  }

  if (data === null) {
    return (
      <div>
        <Spinner animation={'border'} />
      </div>
    );
  }

  return (
    <div>
      {status !== null && (
        <h4>
          {status ? (
            <b style={{ color: Colors.green }}>Erfolgreich gespeichert</b>
          ) : (
            <b style={{ color: Colors.red }}>Fehler beim Speichern</b>
          )}
        </h4>
      )}
      <Formik initialValues={data} onSubmit={submit} validationSchema={gebaeudeinformationenSchema}>
        {({ handleSubmit, handleChange, values, touched, errors, validateForm }) => {
          const onAccordionChange = (eventKey: string | null) => {
            setSelectedAccordion(parseInt(eventKey ?? '-1'));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // Promise with ignored return
            validateForm().then(x => x);
          };

          return (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={'10'}>
                    <h6>*: Pflichtangabe</h6>
                  </Col>
                  <Col md={'2'}>
                    <Button variant={'outline-danger'} as={Col} onClick={logout}>
                      Abmelden
                    </Button>
                  </Col>
                </Row>
                <div className={'mt-3 mb-4'} />
                <Accordion defaultActiveKey="0" onSelect={onAccordionChange}>
                  {[
                    {
                      title: (
                        <span style={touched?.building && errors?.building ? { color: Colors.red } : {}}>
                          Gebäudeinformationen
                        </span>
                      ),
                      component: (
                        <GebaeudeinformationenForm
                          values={values.building}
                          handleChange={handleChange}
                          touched={touched.building}
                          errors={errors.building}
                          prefix={'building'}
                        />
                      ),
                    },
                    {
                      title: (
                        <span
                          style={touched?.exposimeter1 && errors?.exposimeter1 ? { color: Colors.red } : {}}
                        >{`Exposimeter ${values.exposimeter1.exposimeterNr}`}</span>
                      ),
                      component: (
                        <ExposimeterForm
                          values={values.exposimeter1}
                          handleChange={handleChange}
                          touched={touched.exposimeter1}
                          errors={errors.exposimeter1}
                          prefix={'exposimeter1'}
                        />
                      ),
                    },
                    {
                      title: (
                        <span
                          style={touched?.exposimeter2 && errors?.exposimeter2 ? { color: Colors.red } : {}}
                        >{`Exposimeter ${values.exposimeter2.exposimeterNr}`}</span>
                      ),
                      component: (
                        <ExposimeterForm
                          values={values.exposimeter2}
                          handleChange={handleChange}
                          touched={touched.exposimeter2}
                          errors={errors.exposimeter2}
                          prefix={'exposimeter2'}
                        />
                      ),
                    },
                  ].map(({ title, component }, i) => (
                    <Card key={i}>
                      <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
                        {selectedAccordion === i ? <ChevronUp /> : <ChevronDown />} {title}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={i.toString()}>
                        <Card.Body>{component}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
                {touched && Object.keys(touched).length > 0 && errors && Object.keys(errors).length > 0 && (
                  <>
                    <Row className={'mb-3'} />
                    <Row>
                      <Col md={'12'}>
                        <b style={{ color: Colors.red }}>
                          Es gibt noch Fehler im Formular. Bitte korrigieren Sie die Fehler im Formular.
                        </b>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className={'mb-5'} />
                <Button type={'submit'} disabled={submitting}>
                  Speichern
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default GebaeudeinformationenView;
